<template>
    <client-page>
        <sub-visual tit="회사소개" bg="/images/sub/visual/sv--about.jpg"></sub-visual>

        <section class="section--about1 pt-50 pt-lg-80 pb-60 pb-md-120 pb-lg-180">
            <v-container>
                <v-img src="/images/ci.svg" class="w-120px w-lg-180px mb-12 mb-lg-20" data-aos="fade-right"></v-img>
                <p class="page-text--lg line-height-15" data-aos="fade-left">
                    민아트프레임은 1987년 창립 이래 끊임없는 도전과 혁신으로 지속적인 성장을 <br class="d-none d-md-block">
                    거듭해온 광고 프레임 및 LED 라이트 패널 전문 업체로서 다양한 관련 제품을 <br class="d-none d-md-block">
                    개발, 제조, 납품, 수출하고 있습니다.
                </p>
            </v-container>
        </section>

        <section class="section" style="background-image: url(/images/sub/about/s2-bg.jpg)">
            <v-container>
                <div class="tit-wrap text-center">
                    <h3 class="tit font-weight-bold line-height-1 color-white">
                        Our Vision
                    </h3>
                </div>
                <v-row class="word-keep-all">
                    <v-col cols="12" sm="6" md="3">
                        <card-primary borderColor="white" class="effect-none">
                            <div class="pa-20 pa-md-30 color-white">
                                <h5 class="tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num" data-aos="fade-down">
                                    01
                                </h5>
                                <p class="page-text line-height-15" data-aos="fade-up">
                                    끊임없는 도전과 혁신을 통해
                                    최고 품질의 장비를 설계하고
                                    제작합니다.
                                </p>
                            </div>
                        </card-primary>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <card-primary borderColor="white" class="effect-none">
                            <div class="pa-20 pa-md-30 color-white">
                                <h5 class="tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num" data-aos="fade-down">
                                    02
                                </h5>
                                <p class="page-text line-height-15" data-aos="fade-up">
                                    UX 중심의 제품 디자인을 통해
                                    실제 유저가 쉽고 안전하게
                                    사용할 수 있는 수준 높은 제품
                                    구성을 원칙으로 합니다.
                                </p>
                            </div>
                        </card-primary>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <card-primary borderColor="white" class="effect-none">
                            <div class="pa-20 pa-md-30 color-white">
                                <h5 class="tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num" data-aos="fade-down">
                                    03
                                </h5>
                                <p class="page-text line-height-15" data-aos="fade-up">
                                    고객 및 공급업체와 장기적이고
                                    상호이익이 되는 관계를
                                    수립합니다.
                                </p>
                            </div>
                        </card-primary>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <card-primary borderColor="white" class="effect-none">
                            <div class="pa-20 pa-md-30 color-white">
                                <h5 class="tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num" data-aos="fade-down">
                                    04
                                </h5>
                                <p class="page-text line-height-15" data-aos="fade-up">
                                    고객이 필요로 하는 조언과
                                    제품을 제공함으로써 고객의
                                    니즈를 충족합니다.
                                </p>
                            </div>
                        </card-primary>
                    </v-col>
                </v-row>
            </v-container>
        </section> 

        <section class="section secondary color-white">
            <v-container>
                <div class="tit-wrap text-center">
                    <h3 class="tit font-weight-bold">
                        Headquarters & Facilities
                    </h3>
                </div>
                <div class="rounded mb-20 mb-lg-40">
                    <iframe class="w-100 d-block" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3176.470045870956!2d126.8726446!3d37.23655370000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f7ab2b3ec59%3A0x40a3fb94d5273f9f!2z66-87JWE7Yq47ZSE66CI7J6E!5e0!3m2!1sko!2skr!4v1675299710918!5m2!1sko!2skr" width="100%" height="516" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
                <v-row justify="center" no-gutters class="contact-details row-cols-2 row-cols-md-3 row-cols-lg-5">
                    <v-col class="contact-detail pa-20 py-lg-0">
                        <div class="w-100 h-100 d-flex flex-column align-center text-center line-height-13">
                            <i class="icon icon-location mb-12 mb-lg-20" data-aos="zoom-in-up"></i>
                            <p class="page-text--lg font-weight-bold mb-6 mb-lg-10">
                                Location
                            </p>
                            <p class="page-text--sm color-grey-c">
                                <a target="_blank" href="https://www.google.com/maps/place/%EB%AF%BC%EC%95%84%ED%8A%B8%ED%94%84%EB%A0%88%EC%9E%84/data=!3m1!4b1!4m6!3m5!1s0x357c9f7ab2b3ec59:0x40a3fb94d5273f9f!8m2!3d37.2365537!4d126.8726446!16s%2Fg%2F1tj94b_t?hl=ko">
                                    [18284] 경기도 화성시 <br class="d-none d-lg-block">
                                    비봉면 양노로 103
                                </a>
                            </p>
                        </div>
                    </v-col>
                    <v-col class="contact-detail pa-20 py-lg-0">
                        <div class="w-100 h-100 d-flex flex-column align-center text-center line-height-13">
                            <i class="icon icon-mail mb-12 mb-lg-20" data-aos="zoom-in-up"></i>
                            <p class="page-text--lg font-weight-bold mb-6 mb-lg-10">
                                Email
                            </p>
                            <p class="page-text--sm color-grey-c">
                                <a target="_blank" href="mailto:minart369@naver.com">minart369@naver.com</a>
                            </p>
                        </div>
                    </v-col>
                    <v-col class="contact-detail pa-20 py-lg-0">
                        <div class="w-100 h-100 d-flex flex-column align-center text-center line-height-13">
                            <i class="icon icon-home mb-12 mb-lg-20" data-aos="zoom-in-up"></i>
                            <p class="page-text--lg font-weight-bold mb-6 mb-lg-10">
                                Website
                            </p>
                            <p class="page-text--sm color-grey-c">
                                <router-link to="/">minart.com</router-link>
                            </p>
                        </div>
                    </v-col>
                    <v-col class="contact-detail pa-20 py-lg-0">
                        <div class="w-100 h-100 d-flex flex-column align-center text-center line-height-13">
                            <i class="icon icon-tel mb-12 mb-lg-20" data-aos="zoom-in-up"></i>
                            <p class="page-text--lg font-weight-bold mb-6 mb-lg-10">
                                Tel
                            </p>
                            <p class="page-text--sm color-grey-c">
                                <a target="_blank" href="tel:031-465-9088">031-465-9088</a>
                            </p>
                        </div>
                    </v-col>
                    <v-col class="contact-detail pa-20 py-lg-0">
                        <div class="w-100 h-100 d-flex flex-column align-center text-center line-height-13">
                            <i class="icon icon-fax mb-12 mb-lg-20" data-aos="zoom-in-up"></i>
                            <p class="page-text--lg font-weight-bold mb-6 mb-lg-10">
                                Fax
                            </p>
                            <p class="page-text--sm color-grey-c">
                                031-465-9098
                            </p>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import CardPrimary from "@/components/dumb/card-primary.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        CardPrimary,
    },
};
</script>

<style scoped>
.section--about1{
    position: relative;
    overflow: hidden;
}
.section--about1::before{
    content: "Introduction";
    display: block;
    position: absolute;
    line-height: 1;
    color: #f5f5f5;
    font-size: 5rem;
    right: -4px;
    bottom: -8px;
    font-weight: 600;
    z-index: 0;
}
.num{
    width: fit-content;
    border-bottom: 2px solid #fff;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .section--about1::before{
        font-size: 12rem;
        right: -10px;
        bottom: -18px;
    }
}
@media (min-width:1024px){
    .section--about1::before{
        font-size: 16rem;
        right: -14px;
        bottom: -24px;
    }
    .contact-details .contact-detail:not(:last-child){
        border-right: 1px solid rgba(255, 255, 255, 0.3);
    }
}
@media (min-width:1300px){
    .section--about1::before{
        font-size: 20rem;
        right: -14px;
        bottom: -30px;
    }
}

</style>
