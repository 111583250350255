var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "tit": "회사소개",
      "bg": "/images/sub/visual/sv--about.jpg"
    }
  }), _c('section', {
    staticClass: "section--about1 pt-50 pt-lg-80 pb-60 pb-md-120 pb-lg-180"
  }, [_c('v-container', [_c('v-img', {
    staticClass: "w-120px w-lg-180px mb-12 mb-lg-20",
    attrs: {
      "src": "/images/ci.svg",
      "data-aos": "fade-right"
    }
  }), _c('p', {
    staticClass: "page-text--lg line-height-15",
    attrs: {
      "data-aos": "fade-left"
    }
  }, [_vm._v(" 민아트프레임은 1987년 창립 이래 끊임없는 도전과 혁신으로 지속적인 성장을 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 거듭해온 광고 프레임 및 LED 라이트 패널 전문 업체로서 다양한 관련 제품을 "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v(" 개발, 제조, 납품, 수출하고 있습니다. ")])], 1)], 1), _c('section', {
    staticClass: "section",
    staticStyle: {
      "background-image": "url(/images/sub/about/s2-bg.jpg)"
    }
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold line-height-1 color-white"
  }, [_vm._v(" Our Vision ")])]), _c('v-row', {
    staticClass: "word-keep-all"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('card-primary', {
    staticClass: "effect-none",
    attrs: {
      "borderColor": "white"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 color-white"
  }, [_c('h5', {
    staticClass: "tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num",
    attrs: {
      "data-aos": "fade-down"
    }
  }, [_vm._v(" 01 ")]), _c('p', {
    staticClass: "page-text line-height-15",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 끊임없는 도전과 혁신을 통해 최고 품질의 장비를 설계하고 제작합니다. ")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('card-primary', {
    staticClass: "effect-none",
    attrs: {
      "borderColor": "white"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 color-white"
  }, [_c('h5', {
    staticClass: "tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num",
    attrs: {
      "data-aos": "fade-down"
    }
  }, [_vm._v(" 02 ")]), _c('p', {
    staticClass: "page-text line-height-15",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" UX 중심의 제품 디자인을 통해 실제 유저가 쉽고 안전하게 사용할 수 있는 수준 높은 제품 구성을 원칙으로 합니다. ")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('card-primary', {
    staticClass: "effect-none",
    attrs: {
      "borderColor": "white"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 color-white"
  }, [_c('h5', {
    staticClass: "tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num",
    attrs: {
      "data-aos": "fade-down"
    }
  }, [_vm._v(" 03 ")]), _c('p', {
    staticClass: "page-text line-height-15",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 고객 및 공급업체와 장기적이고 상호이익이 되는 관계를 수립합니다. ")])])])], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "3"
    }
  }, [_c('card-primary', {
    staticClass: "effect-none",
    attrs: {
      "borderColor": "white"
    }
  }, [_c('div', {
    staticClass: "pa-20 pa-md-30 color-white"
  }, [_c('h5', {
    staticClass: "tit-txt font-weight-bold line-height-1 pb-6 pb-lg-10 mb-12 mb-lg-20 num",
    attrs: {
      "data-aos": "fade-down"
    }
  }, [_vm._v(" 04 ")]), _c('p', {
    staticClass: "page-text line-height-15",
    attrs: {
      "data-aos": "fade-up"
    }
  }, [_vm._v(" 고객이 필요로 하는 조언과 제품을 제공함으로써 고객의 니즈를 충족합니다. ")])])])], 1)], 1)], 1)], 1), _c('section', {
    staticClass: "section secondary color-white"
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap text-center"
  }, [_c('h3', {
    staticClass: "tit font-weight-bold"
  }, [_vm._v(" Headquarters & Facilities ")])]), _c('div', {
    staticClass: "rounded mb-20 mb-lg-40"
  }, [_c('iframe', {
    staticClass: "w-100 d-block",
    staticStyle: {
      "border": "0"
    },
    attrs: {
      "src": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3176.470045870956!2d126.8726446!3d37.23655370000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f7ab2b3ec59%3A0x40a3fb94d5273f9f!2z66-87JWE7Yq47ZSE66CI7J6E!5e0!3m2!1sko!2skr!4v1675299710918!5m2!1sko!2skr",
      "width": "100%",
      "height": "516",
      "allowfullscreen": "",
      "loading": "lazy",
      "referrerpolicy": "no-referrer-when-downgrade"
    }
  })]), _c('v-row', {
    staticClass: "contact-details row-cols-2 row-cols-md-3 row-cols-lg-5",
    attrs: {
      "justify": "center",
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "contact-detail pa-20 py-lg-0"
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex flex-column align-center text-center line-height-13"
  }, [_c('i', {
    staticClass: "icon icon-location mb-12 mb-lg-20",
    attrs: {
      "data-aos": "zoom-in-up"
    }
  }), _c('p', {
    staticClass: "page-text--lg font-weight-bold mb-6 mb-lg-10"
  }, [_vm._v(" Location ")]), _c('p', {
    staticClass: "page-text--sm color-grey-c"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "https://www.google.com/maps/place/%EB%AF%BC%EC%95%84%ED%8A%B8%ED%94%84%EB%A0%88%EC%9E%84/data=!3m1!4b1!4m6!3m5!1s0x357c9f7ab2b3ec59:0x40a3fb94d5273f9f!8m2!3d37.2365537!4d126.8726446!16s%2Fg%2F1tj94b_t?hl=ko"
    }
  }, [_vm._v(" [18284] 경기도 화성시 "), _c('br', {
    staticClass: "d-none d-lg-block"
  }), _vm._v(" 비봉면 양노로 103 ")])])])]), _c('v-col', {
    staticClass: "contact-detail pa-20 py-lg-0"
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex flex-column align-center text-center line-height-13"
  }, [_c('i', {
    staticClass: "icon icon-mail mb-12 mb-lg-20",
    attrs: {
      "data-aos": "zoom-in-up"
    }
  }), _c('p', {
    staticClass: "page-text--lg font-weight-bold mb-6 mb-lg-10"
  }, [_vm._v(" Email ")]), _c('p', {
    staticClass: "page-text--sm color-grey-c"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "mailto:minart369@naver.com"
    }
  }, [_vm._v("minart369@naver.com")])])])]), _c('v-col', {
    staticClass: "contact-detail pa-20 py-lg-0"
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex flex-column align-center text-center line-height-13"
  }, [_c('i', {
    staticClass: "icon icon-home mb-12 mb-lg-20",
    attrs: {
      "data-aos": "zoom-in-up"
    }
  }), _c('p', {
    staticClass: "page-text--lg font-weight-bold mb-6 mb-lg-10"
  }, [_vm._v(" Website ")]), _c('p', {
    staticClass: "page-text--sm color-grey-c"
  }, [_c('router-link', {
    attrs: {
      "to": "/"
    }
  }, [_vm._v("minart.com")])], 1)])]), _c('v-col', {
    staticClass: "contact-detail pa-20 py-lg-0"
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex flex-column align-center text-center line-height-13"
  }, [_c('i', {
    staticClass: "icon icon-tel mb-12 mb-lg-20",
    attrs: {
      "data-aos": "zoom-in-up"
    }
  }), _c('p', {
    staticClass: "page-text--lg font-weight-bold mb-6 mb-lg-10"
  }, [_vm._v(" Tel ")]), _c('p', {
    staticClass: "page-text--sm color-grey-c"
  }, [_c('a', {
    attrs: {
      "target": "_blank",
      "href": "tel:031-465-9088"
    }
  }, [_vm._v("031-465-9088")])])])]), _c('v-col', {
    staticClass: "contact-detail pa-20 py-lg-0"
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex flex-column align-center text-center line-height-13"
  }, [_c('i', {
    staticClass: "icon icon-fax mb-12 mb-lg-20",
    attrs: {
      "data-aos": "zoom-in-up"
    }
  }), _c('p', {
    staticClass: "page-text--lg font-weight-bold mb-6 mb-lg-10"
  }, [_vm._v(" Fax ")]), _c('p', {
    staticClass: "page-text--sm color-grey-c"
  }, [_vm._v(" 031-465-9098 ")])])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }